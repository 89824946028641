<template>
  <v-card>
      <v-card-title :class="`${color}--text`" v-html="title">
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text >
            <v-alert>
                {{ text }}
            </v-alert>
           <VFormBuilder :fields="fields"
           v-if="answer !== false"
          :defaultsFieldsProps="{
                            rounded: true,
                            dense: true,
                            outlined: true
                        }" 
            ref="form"
          />
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
          <v-btn :color="color" rounded outlined @click="confirm" :loading="loading">
              <v-icon small>{{ confirmIcon}}</v-icon>
              {{ confirmText | t }}
          </v-btn>
          <v-btn  outlined rounded @click="cancel" :disabled="loading">
              <v-icon small >{{ cancelIcon }}</v-icon>
              {{ cancelText | t }}
          </v-btn>
      </v-card-actions>
  </v-card>
</template>

<script>
import VFormBuilder from '@/components/VFormBuilder'
export default {
    components:{
        VFormBuilder
    },
    props: {
        answer: Boolean,
        name: String,
        title: String,
        text: String,
        color: {
            type: String,
            default: 'success'
        },
        confirmText: {
            type: String,
            default: 'ok'
        },
        confirmIcon: {
            type: String,
            default: 'mdi-check'
        },
        cancelText: {
            type: String,
            default: 'cancel'
        },
        cancelIcon: {
            type: String,
            default: 'mdi-close'
        },
        loading: Boolean
    },
    computed: {
        status () {
            // const s = ['pendding', 'confiremed', 'maybe', 'denied']
            return this.answer ? 'confiremed' : ( this.answer === false ? 'denied' : 'maybe') 
                           
        },
        __ () {
            return this.$t
        },
        isComming () {
            return this.answer ? 'yes' : ( this.answer === false ? 'no' : 'maybe') 
        },
         fields () {
             const { $t:__ } = this
            return  [
                {
                    name: 'guests',
                    input: () => import('vuetify/lib/components/VTextField'),
                    attrs: () => ({
                        value: 1,
                        label:__('pepole_number'),
                        disabled: fvm => fvm.values['type'] == 'options',
                        startIcon: 'mdi-plus',
                        endIcon: 'mdi-minus',
                        type: 'number',

                    }),
                    on: formVm => ({
                        startIcon: () => {
                            formVm.values.guests++
                        },
                        endIcon: () => {
                            if (formVm.values.guests > 1)
                                formVm.values.guests--
                            else formVm.values.guests = 1

                        }
                    })
                },
                
            ]
        }
    },
    methods: {
        confirm () {
            
            const { form } = this.$refs
            const data = { status: this.status }
            if (form) {
                form.$on('submit', values => {
                    
                    this.$emit('confirm', {...data, ...values })
                })
                form.submit()
                form.$off('submit')
            } else {
                this.$emit('confirm', data)
            }
            
        },
        cancel () {
            this.$emit('cancel')
        }
    }
}
</script>

<style>

</style>